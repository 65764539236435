.StudySubjects {
    & ul {
        @mixin clearList;

        & li {
            margin: 0;

            &::before {
                content: none;
            }
        }
    }
}

.Semesters {
    @media (--desktop) {
        display: flex;
        flex-wrap: wrap;
    }

    @media print {
        margin-top: 16px;
    }

    & .SemesterHeading,
    & .SpecializationHeading {
        @mixin p;
        margin: 0 0 16px;

        @media print {
            @mixin caption;
            font-size: 10px;
            margin: 0 0 8px;
            break-before: auto;
        }
    }

    & .SpecializationHeading {
        margin-top: 32px;
    }

    & > li {
        padding-top: 16px;
        border-top: 1px solid var(--blackLighten50LegacyColor);

        @media print {
            padding-top: 8px;
        }

        @media (--desktop) {
            lost-column: 6/12 2;

            &:nth-of-type(-n + 2) {
                border: none;
            }
        }

        &:first-of-type {
            border: none;
        }

        & .SubjectList {
            &:last-of-type {
                margin-bottom: 32px;

                @media print {
                    margin-bottom: 8px;
                }
            }

            & > .Subject {
                @mixin label;
                font-weight: 400;
                margin-bottom: 16px;

                @media print {
                    @mixin caption;
                    font-size: 10px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}

.Variant--standalone {
    & .Semesters {
        @media print {
            break-before: page;
        }
    }
}
