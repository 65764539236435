.SubjectOverlayAction {
    position: relative;
}

.Spinner {
    @mixin absoluteParentSize;
    @mixin centerElements;
}

.Control {
    width: 100%;
    margin-bottom: 16px;

    @media (--tablet) {
        width: auto;
    }
}

.Error {
    @mixin p40;
    color: var(--notificationErrorBaseColor);
}
