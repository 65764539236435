.AccordionItemWrapper {
    @mixin wrapper;

    @media (--desktopHD) {
        @mixin grid;
        grid-template-columns: repeat(12, 1fr);
    }
}

.AccordionItem {
    grid-column: 3 / span 8;
}

.AccordionContent {
    @media (--mobileOnly) {
        padding: 16px 32px;
    }
}

.Error {
    @mixin p20;
    color: var(--notificationErrorBaseColor);
}
