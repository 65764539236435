.StudyModelLayout {
    grid-template-columns: minmax(256px, 1fr) 1px minmax(256px, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: 16px 8px;

    & .SeparatorLine,
    & .DashLine {
        grid-column: 2;
        grid-row: 1 / -1;
        justify-self: center;
        border-width: 0 2px 0 0;
    }

    & .Heading {
        grid-row: 1;
        padding: 0 16px 16px 0;
        border-bottom-width: 1px;
        margin-bottom: 8px;

        &.Heading--first {
            grid-column: 1;
        }

        &.Heading--second {
            grid-column: 3;
        }
    }
}

.SingleColumnLayout {
    grid-template-columns: minmax(256px, 1fr);
    grid-template-rows: auto;
}

.Semester {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.Subject {
    min-height: 64px;
}

.Semester--both {
    grid-column: 1 / span 3;
    grid-row: 2;
}

.Semester--first {
    grid-column: 1;
    grid-row: 3;
}

.Semester--second {
    grid-column: 3;
    grid-row: 3;
}

.EmptyMessage {
    @mixin p20;
    padding: 8px;
    grid-column: 1;
}

.ContainerWithLayoutExceedingWrapper {
    & .StudyModelLayout {
        padding: 0 var(--wrapperHorizontalPadding);
        margin: 0 calc(-1 * var(--wrapperHorizontalPadding));
    }
}
