.StudyModelGridContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: var(--blackLighten10Color);

    @media (--desktop) {
        display: grid;
        gap: 24px;
        grid-template-areas:
            'specializations specializations'
            'content content'
            'paths points';
    }
}

.StudyPoints {
    @mixin p30;
    display: block;
    background: var(--blackLighten10Color);
    padding: 16px var(--wrapperHorizontalPadding);
    border-top: 1px solid var(--blackBaseColor);
    border-bottom: 1px solid var(--blackBaseColor);
    margin: -16px calc(-1 * var(--wrapperHorizontalPadding)) 0;

    @media (--desktop) {
        grid-area: points;
        place-self: center end;
        padding: 0;
        border: none;
        margin: 0;
    }
}

.SelectorContainer {
    @mixin animatePropertySlower height;
}

.SpecializationSelectorContainer {
    @media (--desktop) {
        grid-area: specializations;
    }
}

.OptionSelectorContainer {
    @media (--desktop) {
        grid-area: paths;
    }
}

.OptionSelector {
    padding-bottom: 24px;

    @media (--tablet) {
        padding-bottom: 0;
    }
}

.Semesters {
    @media (--desktop) {
        grid-area: content;
        overflow-x: auto;
    }
}
