.StudyModelLayout {
    $minRowHeight: 256px;
    grid-template-rows: minmax($minRowHeight, auto) 3px minmax($minRowHeight, auto);
    row-gap: 16px;

    & .SeparatorLine,
    & .DashLine {
        @mixin spanAllColumns;
        grid-row: 2;
        align-self: center;
        border-width: 2px 0 0;
    }

    & .Heading {
        grid-column: 1;
        writing-mode: vertical-rl;
        text-align: center;
        padding: 16px 0 16px 16px;
        border-left-width: 1px;
        margin: 8px 0;
        transform: rotate(180deg);
        min-height: calc($minRowHeight - 16px);

        &.Heading--first {
            grid-row: 1;
        }

        &.Heading--second {
            grid-row: 3;
        }
    }
}

.SingleColumnLayout {
    grid-template-rows: minmax(64px, auto);
}

.Subject {
    margin-left: 32px;
}

.EmptyMessage {
    @mixin h40;
    padding: 16px;
    grid-column: 2 / -1;
}
