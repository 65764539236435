.SubjectCard {
    cursor: pointer;
    overflow: hidden;

    &:hover {
        & .Header,
        & .PillTag--points {
            background: var(--blackLighten17Color);
        }

        & .Footer {
            background: var(--blackLighten12Color);
        }
    }

    & .Header,
    & .Footer,
    & .PillTag--points {
        @mixin animatePropertySlower background;
    }
}

.Header {
    background: var(--blackLighten15Color);
    padding: 16px;
    margin: 0;
}

.Button {
    @mixin onFocus {
        @mixin focusVisible 4px;
    }
    margin-bottom: 16px;
    width: 100%;

    @media (--tablet) {
        margin-bottom: 32px;
    }
}

.Inner {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.Name {
    @mixin p20;
    @mixin hyphenateWords;
    color: var(--whiteBaseColor);
}

.Code {
    @mixin p40;
    color: var(--blackLighten40Color);
}

.Footer {
    display: flex;
    padding: 12px;
    gap: 16px;
    justify-content: space-between;
    background: var(--blackLighten11Color);
}

.PillTag {
    @mixin p40;
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
}

.Variant--default {
    & .Footer {
        justify-content: flex-end;
    }
}

.ContainerWithStretchingCards {
    & .SubjectCard {
        @mixin fillParent;
        display: flex;
        flex-direction: column;

        & .Header {
            flex: 1;
        }
    }
}
