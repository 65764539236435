.Heading {
    @mixin h30;
    margin: 0 0 16px;
}

.Description {
    margin: 16px 0 32px;
}

.Alert {
    margin: 16px 0;
}
