.StudyModelSection {
    @mixin studyPageSectionPadding;
    background-color: var(--blackBaseColor);
}

.LinkWrapper {
    @mixin wrapper;

    @media (--tablet) {
        @mixin centerElements;
    }
}

.HeaderWrapper {
    @mixin wrapper;

    @media (--desktopHD) {
        @mixin grid;
        grid-template-columns: repeat(12, 1fr);
    }
}

.Header {
    @media (--desktopHD) {
        grid-column: 3 / span 8;
    }
}

.Heading {
    @mixin sectionHeading;
    color: var(--whiteBaseColor);
    margin-bottom: 32px;
}

.StudyModelContent {
    margin-bottom: 48px;
    width: 100%;
}

.TeaserWrapper {
    @media (--tablet) {
        @mixin grid;
        grid-template-columns: repeat(12, 1fr);
    }
}

.Teaser {
    @mixin sectionTeaser;
    display: inline-block;
    color: var(--whiteBaseColor);
    margin-bottom: 24px;

    @media (--tablet) {
        grid-column: 3 / span 8;
    }
}

.DescriptionLink {
    width: 100%;

    @media (--tablet) {
        width: auto;
    }
}
