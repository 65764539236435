.AccordionItemContent {
    width: 100%;

    & .Heading {
        @mixin p40;
    }
}

.PrerequisitesContainer {
    padding-bottom: 32px;
    border-bottom: 1px solid var(--blackLighten50Color);
}

.PrerequisitesHeading {
    @mixin p30;
    display: inline-block;
    margin-bottom: 16px;
}

.Prerequisites {
    @mixin clearList;

    & > li:not(:last-child) {
        margin-bottom: 16px;
    }
}

.Prerequisite {
    @mixin p40;
}
