.StudyModelLayout {
    display: grid;
    background: var(--blackLighten10Color);
    color: var(--whiteBaseColor);
    overflow-x: auto;

    @media (--belowDesktop) {
        scroll-snap-type: x mandatory;

        & .Heading {
            scroll-snap-align: center;
        }
    }

    & .SeparatorLine,
    & .DashLine {
        border-color: var(--whiteBaseColor);
        pointer-events: none;
    }
}

.Heading {
    @mixin p30;
    border: 0 solid var(--blackLighten50Color);
}

.SeparatorLine {
    border-style: solid;
}

.DashLine {
    border-style: dashed;
}

.EmptyMessage {
    @mixin centerElements;
    text-align: center;
    font-weight: 600;
}
